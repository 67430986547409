<template>
    <main v-if="pageData && pageData?.post_type == 'post'" class="single-article" :data-wp-post-id="pageData.id">
        <ArticleView :data="pageData" />
    </main>

    <div v-else-if="pageData && pageData?.post_type == 'page'" class="utl-clearfix" :data-wp-post-id="pageData.id">
        <PartialsPageComponents :components="pageData?.components" />
    </div>
</template>

<script setup>
    import convertRouteToSlug from "~/helpers/convertRouteToSlug";

    const route = useRoute();
    const pageData = ref(null);

    const getPageData = async (nuxtRoute) => {
        const slug = convertRouteToSlug(nuxtRoute);

        const { data } = await useFetch(`/api/wp/page?slug=${slug}`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        pageData.value = data.value;
    };

    const getCanonicalURL = () => {
        const requestUrl = useRequestURL();

        if(!pageData.value) {
            return null;
        }

        if(pageData.value.slug == 'home') {
            return requestUrl.origin + '/';
        }

        if(pageData.value.seo_canonical) {
            return pageData.value.seo_canonical;
        }

        if(pageData.value.custom_permalink) {
            return requestUrl.origin + '/' + pageData.value.custom_permalink.replace(/^\/|\/$/g, '') + '/';
        }
        
        if(pageData.value.slug) {
            return requestUrl.origin + '/' + pageData.value.slug.replace(/^\/|\/$/g, '') + '/';
        }

        return null;
    }

    const getOGImage = () => {

        if(pageData.value.seo_og_images && typeof pageData.value.seo_og_images === 'object' && pageData.value.seo_og_images.length !== 0) {
            return Object.values(pageData.value.seo_og_images)[0];
        }

        if(pageData.value.components && pageData.value.components.length > 0) {
            const firstComponent = pageData.value.components[0];
            if(firstComponent.acf_fc_layout == 'page_header' && firstComponent.slides.length){
                return firstComponent.slides[0]?.image;
            }
        }

        return null;
    }

    await getPageData(route);

    if(pageData.value){

        useSeoMeta({
            title: pageData.value.seo_title,
            ogTitle: pageData.value.seo_og_title,
            ogDescription: pageData.value.seo_description,
            description: pageData.value.seo_description,
            twitterCard: pageData.value.seo_twitter_card,
            ogSiteName: pageData.value.seo_og_site_name,
            ogType: pageData.value.seo_og_type,
            ogLocale: pageData.value.seo_og_locale,
            ogImage: getOGImage(),
            robots: Object.values(pageData.value.seo_robots).join(", "),
            articleModifiedTime: pageData.value.seo_og_article_modified_time,
            ogUrl: getCanonicalURL(),
        })
    
        if(getCanonicalURL()) {
            useHead({
                link: [
                    {
                        rel: "canonical",
                        href: getCanonicalURL()
                    }
                ]
            });
        }
    }

</script>
